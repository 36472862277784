<template>
  <div class="relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">
        Company / Individual Name
        <span class="text-red-500">*</span>
      </label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="enquiry.name"
        type="text"
        placeholder
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Contact First Name</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="primary_contact.firstname"
        type="text"
        placeholder
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Contact Last Name</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="primary_contact.lastname"
        type="text"
        placeholder
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Contact Email</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="primary_contact.email"
        type="email"
        placeholder
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Contact Telephone</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="primary_contact.telephone"
        type="text"
        placeholder
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Enquiry Notes</label>
      <textarea
        v-model="enquiry.notes"
        class="w-full bg-gray-100 border rounded resize-none border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow"
        rows="5"
      ></textarea>
    </div>
    <div class="relative flex justify-between mt-5">
      <div
        v-show="!canSubmitForm"
        class="opacity-50 bg-white absolute inset-0"
      ></div>
      <button
        @click="createEnquiry(false)"
        :disabled="isBusy || !canSubmitForm"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
      <button
        @click="createEnquiry(true)"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6M15 3h6v6M10 14L21 3"
          />
        </svg>
        <span>Save & Open</span>
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import Notification from "@/components/Notification.vue";

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isBusy: false,
      enquiry: {},
      primary_contact: {},
    };
  },
  computed: {
    canSubmitForm() {
      return (
        this.enquiry.name &&
        (this.primary_contact.email || this.primary_contact.telephone)
      );
    },
  },
  methods: {
    async createEnquiry(shouldNavigate) {
      this.isBusy = true;
      try {
        this.enquiry.contacts = [
          {
            ...this.primary_contact,
          },
        ];

        let createResult = await this.SalesProspectService.createProspect(
          this.enquiry
        );
        if (shouldNavigate) {
          this.$router.push({
            name: "prospect-edit",
            params: {
              prospectId: createResult.prospect_id,
              isCreateResult: true,
            },
          });
        } else {
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Enquiry Created",
                    close: onClose,
                  },
                },
                `Enquiry from '${createResult.name}' added`
              );
            },
            {
              position: "top-right",
            }
          );
          this.isBusy = false;
          this.$emit("complete");
        }
      } catch (err) {
        console.error(err);
        let message =
          err.status == 400
            ? "Unable to create new enquiry. Please check all required fields are filled out"
            : "Unable to create new enquiry. Please try again later";

        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              message
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
  },
};
</script>
